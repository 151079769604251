import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import { PRODUCTS } from "data/data";
import CategoryProduct from "containers/Category/CategoryCollection";

import React, { FC, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import SkewLoader from "react-spinners/SkewLoader";
import Category from "containers/PageHome/Category";
export interface PageSearchProps {
  className?: string;
}

const Product: FC<PageSearchProps> = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParam = new URLSearchParams(location.search).get('search');
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0)
  const [color, setColor] = useState("#2A3491");
  const [listdata, setListData] = useState([])
  const [updateListData, setUpdateListData] = useState(false);
  const [keyword, setKeyword] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [productCount, setProductCount] = useState(0);
  const [page, setPage] = useState(1)
  const [is_product, setIsProduct] = useState(false);
  const [filterData, setFilterData] = useState({
    start: 0,
    limit: 12,
    search: searchParam ? searchParam : "",
    category: "",
    sort_by: "title",
    sort_order: "asc",
    page:page
  });

  useEffect(() => {
    let q = searchParam ? searchParam : "";
    setSearchQuery(q)
    if((searchQuery.length>0 && showProduct)|| searchParam){
      getListData();
    }
    setIsApiCall(1);
    // alert(searchParam)
  }, [isApiCall, searchParam]);


  useEffect(() => {
    if(searchParam==null){
        const categoryData = localStorage.getItem("categoryData");
        const moduleName = location.pathname.split("/").pop();
        if (categoryData) {
            const parsedData = JSON.parse(categoryData);
            const mainCategory = parsedData.filter((item: any) => item.slug === moduleName);
            if (mainCategory.length > 0 && mainCategory[0]?.subCategory?.length > 0) {
              setIsProduct(false);
            } else {
              setIsProduct(true);
            }
        }
      }else{
        setIsProduct(false);
      }
  }, [location.pathname]);

  const { data } = useSelector((state: RootState) => state.AppMaster);
  const { loading } = useSelector((state: RootState) => state.AppMaster);

  const getListData = () => {
    if(searchParam!=null){
      filterData.search=searchParam;
    }
    setUpdateListData(true);
    dispatch(clearData())
    dispatch(onGetList(filterData, '', api.frontProductGroup));
  }

  if (data != null && updateListData == true) {
    if (data.length != 0) {
      setUpdateListData(false);
      setListData(data.result);
      setProductCount(data.total_count);
    }
  }

  const onChangeHandler = (event: any) => {
    const { name, value } = event.target;
    setKeyword(value);
    setSearchQuery(value);
    setFilterData((preValue) => {
      return {
        ...preValue,
        search: value,
      };
    });
  }

  // state for showing page
  const [showProduct, setShowProduct] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Construct your query string based on the input value
    if (searchQuery) {
      setShowProduct(true)
      let queryString = `?search=${encodeURIComponent(searchQuery)}`;
      navigate("/products" + queryString);
    } else {
      navigate("/products");
    }
    // Navigate to the "/products" route with the query string

    getListData();
  }

  return (
    <>
      {(is_product)?(
          <CategoryProduct/>
        ):
        (
          <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
            <Helmet>
              <title>Products | Vijaydeep</title>
            </Helmet>
            {loading && (
              <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
                <div className="absolute z-50">
                  <SkewLoader color={color} loading={loading} />
                </div>
              </div>
            )}
            <div
              className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
              data-nc-id="HeadBackgroundCommon"
            />
            <div className="container">
              <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
                <form
                  className="relative w-full"
                  onSubmit={handleSubmit}
                >
                  <label
                    htmlFor="search-input"
                    className="text-neutral-500 dark:text-neutral-300"
                  >
                    <Input
                      className="shadow-lg border-0 dark:border"
                      id="search-input"
                      type="search"
                      placeholder="Enter product code or product Name"
                      sizeClass="pl-14 py-5 pr-5 md:pl-16"
                      rounded="rounded-full"
                      value={searchQuery}
                      onChange={onChangeHandler}
                    />
                    <ButtonCircle
                      className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                      size=" w-11 h-11"
                      type="submit"
                    >
                      <i className="las la-arrow-right text-xl"></i>
                    </ButtonCircle>
                    <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                      <svg
                        className="h-5 w-5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22 22L20 20"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </label>
                </form>
              </header>
            </div>

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
              <main>
                {/* FILTER */}
                {/* <HeaderFilterSearchPage
                  filterData={filterData}
                  getListData={getListData}
                  setFilterData={setFilterData}
                /> */}

                {/* LOOP ITEMS */}
                {
                  ( (searchQuery.length>0 && showProduct) || searchParam)?
                    (
                      <>
                      <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                        {listdata && listdata.map((item, index) => (
                          <ProductCard data={item} key={index} />
                        ))}
                      </div>
                      <div className="flex justify-left items-center mt-10 gap-x-10">
                        {/* <button className="text-black bg-white border border-black rounded-3xl px-4 py-2 shadow-md hover:bg-gray-200" onClick={handelInfiniteScroll}>Load More</button> */}
                        <Pagination
                          filterCategoryData={filterData}
                          getListData={getListData}
                          listCount={productCount}
                          searchQuery={searchQuery}
                        />
                      </div>
                      </>
                    ) :
                    (
                      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
                        <Category getListData={getListData} filterData={filterData} setShowProduct={setShowProduct}/>
                      </div>
                    )
                }


                {/* <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                  {listdata && listdata.map((item, index) => (
                    <ProductCard data={item} key={index} />
                  ))}
                </div> */}

                {/* PAGINATION */}
                <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                  {/*  <Pagination />
                  <ButtonPrimary loading>Show me more</ButtonPrimary> */}
                </div>
              </main>

              {/* === SECTION 5 === */}
              {/* <hr className="border-slate-200 dark:border-slate-700" />
              <SectionSliderCollections />
              <hr className="border-slate-200 dark:border-slate-700" /> */}

              {/* SUBCRIBES */}
              {/* <SectionPromo1 /> */}
            </div>
          </div>
        )
      }
    </>
  );

};

export default Product;
