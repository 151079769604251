import React, { FC,useEffect,useState } from "react";

import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import MySwitch from "components/MySwitch";
import { NavLink } from "react-router-dom";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

export interface SidebarFilters {
  getListData: any,
  filterCategoryData:any
}
//
const SidebarFilters:FC<SidebarFilters> = ({
  getListData,
  filterCategoryData
}) => {
  const location = useLocation();
  const path = location.pathname;
  const pathSegments = path.split('/').filter(Boolean);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);

  //
  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };
  //

  const setIsApiCall=(id:any)=>{
    filterCategoryData.category =id
    filterCategoryData.page =1
    filterCategoryData.start =0
    getListData()
  }

  const [categoriesData,setCategoryData] =useState([]);

  useEffect(() => {
    let listData:any = localStorage.getItem("categoryData")
    let transformedData = [];
    const categoryData = JSON.parse(listData);
    const mainCategory = categoryData.filter((item: any) => item.slug==pathSegments[0]);
    if(mainCategory[0]?.subCategory && mainCategory[0]?.subCategory.length>0){
      transformedData = mainCategory[0].subCategory.map((item:any, index: number) => ({
        href:"/"+pathSegments[0]+"/"+item.slug,
        name: item.name,
        id: item._id,
      }));
    }
    setCategoryData(transformedData);
  }, [path])

  // OK
  const renderTabsCategories = () => {

    return (
      <div className="relative flex flex-col pb-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Categories</h3>
        {categoriesData.map((item:any) => (
          <div key={item.name} className="">
            <NavLink
                to={{
                pathname: item.href || undefined,
                }}
                onClick={() => setIsApiCall(item.id)}
            >
                {item.name}
            </NavLink>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="divide-y divide-slate-200 dark:divide-slate-700">
      {renderTabsCategories()}
    </div>
  );
};

export default SidebarFilters;
