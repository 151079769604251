import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import withRouter from "components/Common/withRouter";

export interface CategoryProps {
  className?: string;
  getListData?: any;
  filterData?: any;
  setShowProduct?: any;
}

const Category: FC<CategoryProps> = ({
  className,
  getListData,
  filterData,
  setShowProduct
}) => {
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const location = useLocation();
  const path = location.pathname;
  const moduleName = path.split('/').pop(); // Extracts the module name from the path.

  useEffect(() => {
    // Fetch category data when the route changes.
    const fetchCategoryData = () => {
      const listData: string | null = localStorage.getItem("categoryData");
      if (listData) {
        const categorySessionData = JSON.parse(listData);
        const mainCategory = categorySessionData.filter((item: any) => item.slug === moduleName);

        if (mainCategory.length > 0 && mainCategory[0].subCategory?.length > 0) {
          setCategoryData(mainCategory[0].subCategory);
        } else {
          setCategoryData([]); // Reset if no data matches.
        }
      }
    };

    fetchCategoryData();
  }, [moduleName]); // Dependency array ensures it runs on `moduleName` changes.

  const applyFilter = (categoryId: string) => {
    if (location.pathname !== "/") {
      setShowProduct?.(true);

      filterData.category = categoryId; // Assuming `filterData` is mutable.
      getListData?.(); // Trigger the function to fetch filtered data.
    }
  };

  return (
    <div
      className={`nc-Card13 relative grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8`}
    >
      {categoryData.map((item: any, index: number) => (
        <div key={item.id} className="">
          <Link to={item.slug}>
            <NcImage
              className="rounded-2xl"
              src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${item.image_data.pathname}`}
            />
          </Link>
          <Link to={item.slug}>
            <div className="space-y-1 px-2.5 pt-5 pb-2.5">
              <h2 className="md:text-base text-sm font-semibold text-neutral-300 hover:text-secondary-900">
                {item.name}
              </h2>
              <span className="block text-secondary-900 hover:text-neutral-900 text-sm leading-6">
                Read More
              </span>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default withRouter(Category);
