import React from "react";
import NavigationItem from "./NavigationItem";
//import { NAVIGATION_DEMO_2 } from "data/navigation";
import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

function Navigation() {
  let listData: any = localStorage.getItem("categoryData");
  let transformedData: any[] = [];
  let mainCategories: any[] = [];
  const categoryData = JSON.parse(listData);

  // Set to track all slugs used as subcategories
  const subCategorySlugs = new Set<string>();

  // Helper function to collect all subcategories' slugs
  const collectSubCategorySlugs = (categories: any[]) => {
    categories.forEach((item: any) => {
      if (item.subCategory && item.subCategory.length > 0) {
        item.subCategory.forEach((subItem: any) => {
          subCategorySlugs.add(subItem.slug);
        });

        // Recursively collect slugs from deeper levels
        collectSubCategorySlugs(item.subCategory);
      }
    });
  };

  // Step 1: Collect all subcategory slugs
  if (categoryData && Array.isArray(categoryData)) {
    collectSubCategorySlugs(categoryData);

    mainCategories = categoryData.filter(
      (item: any) => !subCategorySlugs.has(item.slug) // Exclude categories that exist in subcategories
    );
  }

  // Step 3: Transform the remaining categories into the desired structure
  const transformCategories = (categories: any[], level: number = 1, parentSlug: string = ''): any[] => {
    return categories.map((item: any) => {
      let transformedItem: any = {
        id: ncNanoId(),
        href: parentSlug + "/" + item.slug, // Construct the href dynamically with parentSlug
        name: item.name,
        type: "megaMenu", // Default type
      };
      let url = parentSlug + "/" + item.slug;

      // Process subcategories recursively
      if (item.subCategory && item.subCategory.length > 0 && level < 10) {
        transformedItem["children"] = transformCategories(item.subCategory, level + 1, url);
      }

      // If it's the last level and has no subcategories, remove the "type" key
      if (!item.subCategory || item.subCategory.length === 0) {
        delete transformedItem.type; // Remove the "type" key if no subcategories
      }

      return transformedItem;
    });
  };

  if (mainCategories && mainCategories.length > 0) {
    transformedData = transformCategories(mainCategories);
  }

  const MAIN_TEMPLATES: NavItemType[] = transformedData;

  const NAVIGATION_DEMO_2: NavItemType[] = [
    {
      id: ncNanoId(),
      href: "/",
      name: "Home",
    },
    {
      id: ncNanoId(),
      href: "/about",
      name: "About Us",
    },
    {
      id: ncNanoId(),
      href: "/category",
      name: "Products",
      type: "dropdown",
      children: MAIN_TEMPLATES, // Products have categories as dropdown children
    },
    {
      id: ncNanoId(),
      href: "/contact",
      name: "Contact Us",
    },
  ];

  return (
    <ul className="nc-Navigation flex items-center">
      {NAVIGATION_DEMO_2.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
