import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

export interface ProductCardProps {
  className?: string;
  data?: any;
  isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
}) => {

  
  return (
    <>
      <div
        className={`nc-ProductCard rounded-xl shadow-lg border-0 space-y-0 ${className}`}
        data-nc-id="ProductCard"
      >
        <Link to={`/category/${data?.category[0]?.slug}/${data?.slug}`} className="">
        <NcImage
            containerClassName=""
            src={data?.image.url}
          />
        <div className="border-t-4 border-[#2a3491]"></div>
        <div className="p-5 text-center">

          <h2
            className={`nc-ProductCard__title text-base font-semibold transition-colors hover:text-secondary-900`}
          >
            {data?.sub_title}
          </h2>
        </div>
        </Link>
      </div>
    </>
  );
};

export default ProductCard;
